import 'vant/lib/index.css';

import { createRouter, createWebHistory } from 'vue-router'
import { createApp } from 'vue'
import App from './App.vue'

import Home from './components/Home.vue'
import About from './components/About.vue'
import History from './components/History.vue'
import Product from './components/Product.vue'
import Customer from './components/Customer.vue'
import Advantage from "@/components/Advantage.vue";

import { Cell, CellGroup,  NavBar, Tabbar, TabbarItem, Icon, Dialog, Popup, Step, Steps, Image } from 'vant';

const routes = [
    { path: '/', component: Home, meta: { title: '上海紫泛企业管理咨询有限公司' } },
    { path: '/about', component: About, meta: { title: '关于我们' } },
    { path: '/history', component: History, meta: { title: '发展历程' } },
    { path: '/advantage', component: Advantage, meta: { title: '对比优势' } },
    { path: '/product/:productIndex', component: Product, meta: { title: '产品介绍' } },
    { path: '/customer', component: Customer, meta: { title: '服务客户' } },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

const app = createApp(App)
app.use(Cell)
app.use(CellGroup)
app.use(NavBar)
app.use(Tabbar)
app.use(TabbarItem)
app.use(Image)
app.use(Icon)
app.use(Dialog)
app.use(Popup)
app.use(router)
app.use(Step)
app.use(Steps)

app.mount('#app')
