<template>
  <div class="info">
    <div>紫泛成立于2013年，拥有一流财税、人力、法务实务专家，为企业提供全程专业咨询及服务保障，通过自主研发财税服务平台，实现企业管理系统支撑，致力于建设覆盖全国400个主要城市工商、财税、 人力、社保、结算一站式线上线下服务平台。通过赋能中小企业不断优化财务制度和流程，使工作得到标准化的交付结果，提供合适的人才与可控
的财务管理流程。
    </div>
    <div>
依托自身具备的丰富行业经验与技术能力支撑，紫泛实现了全方位 （服务范围覆盖全国）、全流程 （服务能力从线下贯通线上）、全生命周期（服务范围包括公司设立、经营中的疑难、税筹、 公司股改/兼并、投融资、IPO筹划等各项业务）的综合性服务。总部设立在上海，并在苏州、浙江、香港设有分公司，作为SAP的合作伙伴以及标淮化交付的服务理念，我们与众多国际知名企业创建了合作，从而也在不断学习和优化更多的管理经验，完善在服务过程中制定的控制点。
    </div>
  </div>
</template>
<script>
export default {
  name: "OfficialAbout",
  computed: {

  },
  methods: {

  },
}
</script>
<style scoped>
.info {
  padding: 20px;
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
}
  .info div {
    margin-bottom: 10px;
  }
</style>