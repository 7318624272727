<template>
    <div>
        <van-cell-group inset title="基础记账服务" v-if="productIndex == 1">
            <van-cell>
                <template #title>
                    <span class="custom-title">报销凭据合规审核</span>
                </template>
            </van-cell>
            <van-cell>
                <template #title>
                    <span class="custom-title">原始凭证复核</span>
                </template>
            </van-cell>
            <van-cell>
                <template #title>
                    <span class="custom-title">编制会计凭证</span>
                </template>
            </van-cell>
            <van-cell>
                <template #title>
                    <span class="custom-title">录入总账、明细账</span>
                </template>
            </van-cell>
            <van-cell>
                <template #title>
                    <span class="custom-title">往来核算</span>
                </template>
            </van-cell>
            <van-cell>
                <template #title>
                    <span class="custom-title">计提税金</span>
                </template>
            </van-cell>
            <van-cell>
                <template #title>
                    <span class="custom-title">编制企业报表</span>
                </template>
            </van-cell>
            <van-cell>
                <template #title>
                    <span class="custom-title">网上税务申报</span>
                </template>
            </van-cell>
            <van-cell>
                <template #title>
                    <span class="custom-title">扣缴税款</span>
                </template>
            </van-cell>
            <van-cell>
                <template #title>
                    <span class="custom-title">记账凭证打印</span>
                </template>
            </van-cell>
            <van-cell>
                <template #title>
                    <span class="custom-title">凭证整理装订成册</span>
                </template>
            </van-cell>
            <van-cell>
                <template #title>
                    <span class="custom-title">所得税清算</span>
                </template>
            </van-cell>
        </van-cell-group>


        <van-cell-group inset title="外派财务服务" v-if="productIndex == 2">
            <van-cell>
                <template #title>
                    <span class="custom-title">基础记账服务+</span>
                </template>
            </van-cell>
            <van-cell>
                <template #title>
                    <span class="custom-title">流程管理</span>
                </template>
            </van-cell>
            <van-cell>
                <template #title>
                    <span class="custom-title">一对一财务咨询</span>
                </template>
            </van-cell>
            <van-cell>
                <template #title>
                    <span class="custom-title">健全优化财务体系</span>
                </template>
            </van-cell>
            <van-cell>
                <template #title>
                    <span class="custom-title">成本费用评估管理</span>
                </template>
            </van-cell>
            <van-cell>
                <template #title>
                    <span class="custom-title">企业利润评估管理</span>
                </template>
            </van-cell>
            <van-cell>
                <template #title>
                    <span class="custom-title">企业应纳税及税负评估</span>
                </template>
            </van-cell>
            <van-cell>
                <template #title>
                    <span class="custom-title">管理风险提示、定期汇报</span>
                </template>
            </van-cell>
            <van-cell>
                <template #title>
                    <span class="custom-title">最新政策解读</span>
                </template>
            </van-cell>
            <van-cell>
                <template #title>
                    <span class="custom-title">税收筹划</span>
                </template>
            </van-cell>
            <van-cell>
                <template #title>
                    <span class="custom-title">制度实施跟进</span>
                </template>
            </van-cell>
        </van-cell-group>



        <van-cell-group inset title="专项财务服务" v-if="productIndex == 3">
            <van-cell>
                <template #title>
                    <span class="custom-title">外派财务服务+</span>
                </template>
            </van-cell>
            <van-cell>
                <template #title>
                    <span class="custom-title">股权搭建</span>
                </template>
            </van-cell>
            <van-cell>
                <template #title>
                    <span class="custom-title">深度财税咨询</span>
                </template>
            </van-cell>
            <van-cell>
                <template #title>
                    <span class="custom-title">财务记账指导</span>
                </template>
            </van-cell>
            <van-cell>
                <template #title>
                    <span class="custom-title">财务团队人员培</span>
                </template>
            </van-cell>
            <van-cell>
                <template #title>
                    <span class="custom-title">训投融资项目对接</span>
                </template>
            </van-cell>
            <van-cell>
                <template #title>
                    <span class="custom-title">政府扶持资金</span>
                </template>
            </van-cell>
            <van-cell>
                <template #title>
                    <span class="custom-title">内部审计</span>
                </template>
            </van-cell>
            <van-cell>
                <template #title>
                    <span class="custom-title">投融资计划</span>
                </template>
            </van-cell>
            <van-cell>
                <template #title>
                    <span class="custom-title">高新方案</span>
                </template>
            </van-cell>
            <van-cell>
                <template #title>
                    <span class="custom-title">知识产权规划</span>
                </template>
            </van-cell>
        </van-cell-group>


        <van-cell-group inset title="高级财税服务" v-if="productIndex == 4">
            <van-cell>
                <template #title>
                    <span class="custom-title">专项财务服务+</span>
                </template>
            </van-cell>
            <van-cell>
                <template #title>
                    <span class="custom-title">IPO前准备</span>
                </template>
            </van-cell>
            <van-cell>
                <template #title>
                    <span class="custom-title">兼并购方案</span>
                </template>
            </van-cell>
            <van-cell>
                <template #title>
                    <span class="custom-title">资本获取</span>
                </template>
            </van-cell>
            <van-cell>
                <template #title>
                    <span class="custom-title">股东税筹搭建</span>
                </template>
            </van-cell>
            <van-cell>
                <template #title>
                    <span class="custom-title">其他个性化定制服务</span>
                </template>
            </van-cell>
        </van-cell-group>
    </div>
  </template>
  <script>
  // Home.vue
  export default {
    name: "ZfindProduct",
    data() {
      return {
        productIndex: null,
      };
    },
    computed: {

    },
    created() {
        this.productIndex = this.$route.params.productIndex
        this.$watch('$route.params.productIndex', (val) => {
            this.productIndex = val
        })
    },
    methods: {
  
    },
  }
  </script>
  <style scoped>
  .custom-title {
    display: flex;
    flex: 1;
  }
  </style>